import NotFoundErrorTemplate from "./errorTemplate";
const InternalErrorTemplate = (props) => {

    const {
        statusCode,
        menu,
        widgets
    } = {...props};

    return ( 
        <>
          {statusCode
            ? <NotFoundErrorTemplate statusCode={statusCode}/>
            : 'An error occurred on client'}
        </>
      )
}

export default InternalErrorTemplate;