import { useState } from 'react';
import Head from "next/head";
import Header from '@/shared/components/Header';
import styles from './styles.module.css';
import Templates from "@/widgets/index";
import Chartbeat from '@/shared/components/Chartbeat';

function NotFoundErrorTemplate(props) { 
  const [search, setSearch] = useState('');
  let properties = {};
  let title = "Pagina no encontrada";
  let description = {};
  let menues = props.menu ? props.menu : [];
  
  const [open, setOpen] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const urlSearch = `/busqueda#gsc.q=${search}&gsc.sort=date`;
    window.location.href = urlSearch;
  } 
  let dataLDJson =  {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Diario El Litoral",
    "url": "https://www.ellitoral.com",
    "logo": {
      "@type": "ImageObject",
      "url": `${process.env.NEXT_PUBLIC_DOMAIN_URL}/img/header_logoellitoral.svg`,
      "width": 439,
      "height": 60
    },
    "sameAs": ["https://www.facebook.com/ellitoralcom", "https://instagram.com/ellitoral", "https://twitter.com/ellitoral", "https://plus.google.com/+EllitoralArgentina"]
  };
  return (
    <>
        <Head>
            <title>{title}</title>
            <meta name="description" property="description" content="El Litoral. Noticias actualizadas las 24 horas."/>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(dataLDJson) }} /> 
            <meta name="og:url" property="og:url" content={'https://www.ellitoral.com/' } />
            <meta name="og:type" property="og:type" content="article" />
            <meta name="og:title" property="og:title" content={title} />
            <meta name="og:description" property="og:description" content={description} />
            <meta name="og:image" property="og:image" content={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/img/img-default-share.jpeg`} />
            <meta httpEquiv="refresh" content="600" />
        </Head>
      <Header menues={menues} isOpenSidePanel={(open) => setOpen(open)} />
      <div className={`${styles['pne-container']}`}>
      {!props.preview && <Templates.BannerHorizontalChico bannerId={`horizontal1`} />}
      <Chartbeat sections={properties} />
      </div>
      <div className={`${styles['pne-container']}`}>
          <h3>{props.statusCode || 404}</h3>
          <h1>La página que usted solicitó <strong>no se encuentra disponible</strong> en este momento</h1>
          <form onSubmit={handleSubmit} className={`${styles['pne-form']}`}>
              <label htmlFor={"searchName"}>¿Qué estás buscando?</label>
              <input className={`${styles['pne-input']}`} 
                autoComplete="off" 
                type="text" 
                name="searchName"
                id={`searchName`}
                value={search} 
                onChange={(event) => setSearch(event.target.value)} 
              />
              <button type="submit" >Buscar</button>
          </form>

      </div>
    </>
  )
}

export default NotFoundErrorTemplate;