import { useAppContext } from '@pagegrowler_nextjs';
import { PagegrowlerBackendInstance } from '@pagegrowler_nextjs_ssr';

import headers from '@/lib/headers.js';
import NotFoundTemplate from './404.js';
import InternalErrorTemplate from './500.js';
import { pagegrowlerConfigBackend } from '../pagegrowler.config';

import { rules } from '@/config/rules'

export async function getServerSideProps(context) {
    const pagegrowlerFrontendApiUrl = process.env.PAGEGROWLER_FRONTEND_API ?? process.env.NEXT_PUBLIC_PAGEGROWLER_FRONTEND_API;

    let pagegrowlerBackend = PagegrowlerBackendInstance();
    let lastModified = null;
    pagegrowlerBackend.setPagegrowlerFrontendApiUrl(pagegrowlerFrontendApiUrl);
    pagegrowlerBackend.setEndpointConfig(rules);
    pagegrowlerBackend.setExternalConfig(pagegrowlerConfigBackend);

    const result = await pagegrowlerBackend.handleGetServerSideProps(context, false);
    if (result.notFound === true) {
        const { res } = context;
        res.statusCode = result.props.status;
        let propsNotFound = result.props;
        propsNotFound.uri = context.resolvedUrl;
        return { props: { errorCode: result.props.status, data: propsNotFound } };
    }
    //Detectar si estoy en la url o template de Necrologicas
    if (!result.notFound) {
        if (result.props.matchConfig.type === 'redirect') { //redireccion dura
            const { res } = context;
            res.writeHead(301, { location: result.props.matchConfig.config.url });
            res.end();
        }

        if (result.props.matchConfig.template === 'redirect') { //seguramente esto sea para detailOld
            const { res } = context;
            res.writeHead(301, { location: result.props.matchConfig.url });
            res.end();
        }
    }
    
    if (result.props && result.props.matchConfig) {

        lastModified = headers(result.props.matchConfig);
        if (lastModified !== null) {
            context.res.setHeader('Last-Modified', lastModified);
        }
        if (result.props.matchConfig.template === 'Nota') {
            const { res, resolvedUrl } = context;
            const { content: { url } } = result.props.matchConfig;
            if (resolvedUrl !== url && resolvedUrl.includes('previews-mode') === false) {
                res.writeHead(301, { location: url });
                res.end()
            }
        }
    }
    return result;
}

const Match = (props) => {

    const {
        matchConfig,
        errorCode,
        data
    } = props;

    if(errorCode) {
        if(errorCode === 404) {
            return <NotFoundTemplate statusCode={errorCode} widgets={data.widgets} menu={data.menu} />
        } else {
            return <InternalErrorTemplate statusCode={errorCode}/>
        }
    }

    let Pagegrowler = useAppContext().pagegrowler;
    return Pagegrowler.handleMatch(matchConfig);
}

export default Match;
