import NotFoundErrorTemplate from "./errorTemplate";

const NotFoundTemplate = (props) => {

    const {
        statusCode,
        menu,
        widgets
    } = {...props};

    return ( 
        <>
          {statusCode
            ? <NotFoundErrorTemplate statusCode={statusCode} menu={menu} widgets={widgets}/>
            : 'An error occurred on client'}
        </>
      )
}

export default NotFoundTemplate;